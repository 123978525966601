@import '../../../../scss/theme-bootstrap';

.footer-links-image {
  &__title {
    font-family: $base-bolder-font-family;
    font-size: 15px;
    letter-spacing: -0.01em;
    margin-bottom: 16px;
    @media #{$cr19-large-up} {
      font-size: 17px;
    }
  }
  &__link {
    margin-bottom: 13px;
  }
  &__inline {
    .footer-links-image__image {
      display: inline-flex;
    }
    .text-link--style-2 {
      display: inline-flex;
    }
  }
  &__ab-link {
    position: relative;
    .footer-links-image__image {
      position: absolute;
      top: 25px;
    }
    .text-link--style-2 {
      position: absolute;
      margin-#{$ldirection}: 5px;
    }
  }
  &__image-only {
    .text-link--style-2 {
      display: none;
    }
  }
}
